

































import { Vue, Component } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/User';
import { getImgVerifyCode, getLoginConfig, LoginType, loginUserMeansCode, UserAccountLogin, userAccountLogin, UserCenterInfoType } from '@/api/user';
import { setToken } from '@/utils/storage';
import md5 from 'js-md5';
@Component({
  name: ''
})
export default class extends Vue {
  onFinish = false;
  isSendCode = true;
  userAccountInfo = {} as UserAccountLogin;
  imageVerifyInfo = {
    imgBase64: '',
    token: ''
  };
  time = 60 * 1000;
  sendEmail() {
    console.log('发送邮件');
    this.isSendCode = false;
    // this.$refs.countDown.start();
  }
  async login() {
    try {
      const { status, payload } = await userAccountLogin({ ...this.userAccountInfo, userPassword: md5(this.userAccountInfo.userPassword) , code: this.$route.query.code, wechatType: "h5"});
      console.log(status);
      UserModule.setUserInfo(payload.userInfo);
      setToken(payload.jwt);
      const path = sessionStorage.getItem('backUrl');
      await this.$router.push(path || 'index');
      sessionStorage.removeItem('backUrl');
    } catch (e) {
      await this.getImageCode();
    }
  }
  async getImageCode() {
    const { payload } = await getImgVerifyCode();
    this.imageVerifyInfo = payload;
    this.userAccountInfo.imgToken = this.imageVerifyInfo.token;
  }
  async getLoginConfig() {
    const { payload } = await getLoginConfig();
    console.log(payload);
    if (payload.loginType === LoginType.MEANS) {
      window.location.replace(`${payload.replaceUrl}?callbackUrl=${encodeURIComponent(window.location.origin + window.location.pathname)}`);
    }
  }

  async loginUserMeansCode(code: string) {
    const { payload } = await loginUserMeansCode(code);
    UserModule.setUserInfo(payload.userInfo);
    setToken(payload.jwt);
    const path = sessionStorage.getItem('backUrl');
    await this.$router.push(path || 'index');
    sessionStorage.removeItem('backUrl');
  }
  created() {
    const route = this.$route;
    console.log(route.query.code)
    if (route.query.code === '' || route.query.code === undefined) {
      console.log("当前yrl")
      console.log(window.location.href)
      console.log(encodeURIComponent(window.location.href))
      alert("进行微信授权链接" + `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc3768f25d03c27c6&redirect_uri=https%3A%2F%2Fh5test.yunwangl.com%2Fdc0bcb99%2Flogin&response_type=code&scope=snsapi_base&state=%s#wechat_redirect`)
      window.location.href =
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc3768f25d03c27c6&redirect_uri=https%3A%2F%2Fh5test.yunwangl.com%2Fdc0bcb99%2Flogin&response_type=code&scope=snsapi_base&state=%s#wechat_redirect`;
    }

    if (route.query.backUrl) {
      sessionStorage.setItem('backUrl', decodeURIComponent(route.query.backUrl as string));
    }

    if (route.query.callbackCode) {
      this.$router.replace({ name: 'Login' });
      this.loginUserMeansCode(route.query.callbackCode as string);
    } else {
      this.getLoginConfig();
    }
    this.getImageCode();
  }
}
